import React,  { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import LandingRoute from './LandingRoute';
const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    {window.location.pathname.includes("/landing") ? <LandingRoute /> : <App />}
  </BrowserRouter>

  
);
reportWebVitals();
