import React, { useState, useEffect } from "react";
import logo from "../Assets/imgs/landing-logo.webp";
import mainimage from "../Assets/imgs/landing-main-img.webp";
import "../styles/landing.css";

type Props = {
  inputRef: React.RefObject<HTMLInputElement>[];
  otp: {
    one: string;
    two: string;
    three: string;
    four: string;
  };
  handleOtpInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleBackspace: (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => void;
  onOtpSubmit: () => void;
  error: string;
  loader: boolean;
  showErro: boolean;
  timer: number;
  showResendButton: boolean;
  onResendOtp: () => void;
};

const OtpPage = (props: Props) => {
  return (
    <div className="landing-bg startalk-landing">
      <div className="subscrption-page">
        <div className="subscrption-logo">
          <img src={logo} width={100} height={100} alt="logo image" />
        </div>
      </div>

      <div className="subscrption-content ">
        <div className="subscrption-inner">
          <div className="image">
            <img src={mainimage} width={355} height={323} alt="image" />
          </div>
          <div className="form">
            <div className="input-content">
              <div className="text otp">
                Enter the OTP received on the phone number
              </div>

              <div className="otp-inputs">
                <input
                  type="tel"
                  name="one"
                  maxLength={1}
                  ref={props.inputRef[0]}
                  value={props.otp.one}
                  onChange={(e) => props.handleOtpInput(e, 0)}
                  onKeyDown={(e) => props.handleBackspace(e, 0)}
                  autoFocus
                />
                <input
                  type="tel"
                  name="two"
                  maxLength={1}
                  ref={props.inputRef[1]}
                  value={props.otp.two}
                  onChange={(e) => props.handleOtpInput(e, 1)}
                  onKeyDown={(e) => props.handleBackspace(e, 1)}
                  // autoFocus
                />
                <input
                  type="tel"
                  name="three"
                  maxLength={1}
                  ref={props.inputRef[2]}
                  value={props.otp.three}
                  onChange={(e) => props.handleOtpInput(e, 2)}
                  onKeyDown={(e) => props.handleBackspace(e, 2)}
                  // autoFocus
                />
                <input
                  type="tel"
                  name="four"
                  maxLength={1}
                  ref={props.inputRef[3]}
                  value={props.otp.four}
                  onChange={(e) => props.handleOtpInput(e, 3)}
                  onKeyDown={(e) => props.handleBackspace(e, 3)}
                  // autoFocus
                />
              </div>
            </div>

            {props.showErro ? (
              <>
                <p className="error" style={{ textAlign: "center" }}>
                  {props.error}
                </p>
              </>
            ) : (
              <></>
            )}

            {props.loader ? (
              <>
                <button className="disable-btn">
                  <span>Loading...</span>
                </button>
              </>
            ) : (
              <>
                <button onClick={props.onOtpSubmit}>Submit OTP</button>
              </>
            )}

            {/* <button>Submit OTP</button> */}

            <div className="resend-code">
              {props.showResendButton ? (
                <div className="timer-text">
                  <span onClick={props.onResendOtp}>Resend OTP</span>
                </div>
              ) : (
                <div className="not-received">
                  <span>Resend</span> 00:
                  {props.timer < 10 ? `0${props.timer}` : props.timer}
                </div>
              )}
            </div>

            <div className="prepaid-text">
              * Note: Only for Rs. 5 incl of Tax/Day (Prepaid) *
            </div>

            <div className="condition">
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>

              <span></span>

              <a href="/terms" target="_blank">
                Terms & Conditions{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
