import { IGenerateToken, IGetFlow } from "../@types/apiresponse";
import { ApiName } from "../Constant/ApiName";
import { password, username } from "../utils/config";
import { fetchApiGet, fetchApiPost } from "./NetWorkServices";

export const generateTokenService = async () => {
  let requestData = {
    username: username,
    password: password,
  };
  let data: IGenerateToken = await fetchApiPost(
    "",
    ApiName.generateGwtToken,
    requestData,
    ""
  );
  return data;
};

export const getFlowServices = async (
  token: string | null,
  endPoint: string
) => {
  let data: IGetFlow = await fetchApiGet(token, endPoint, "");
  return data;
};
