import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpPage from "../pages/OtpPage";
import { ApiName } from "../Constant/ApiName";
import { fetchApiGet, fetchApiPost } from "../services/NetWorkServices";
import {
  navigateToHome,
  triggerSubscriptionEvent,
} from "../utils/CommonFunction";

type Props = {
  jwtToken: string | null;
};
let adNetwork = "";
const OtpPageScreen = ({ jwtToken }: Props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [inputRefs] = useState(
    Array.from({ length: 4 }, () => React.createRef<HTMLInputElement>())
  );
  const [otp, setOtp] = useState({ one: "", two: "", three: "", four: "" });
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    if (!state?.msisdn) return navigate("/landing");

    const initializeOtpFlow = () => {
      checkUtms();
      if (state.isSpecialFlow) {
        handleSpecialFlow();
      } else if (state.otp) {
        const [one, two, three, four] = state.otp.split("");
        setOtp({ one, two, three, four });
      }
    };
    initializeOtpFlow();
  }, []);

  useEffect(() => {
    if (showResendButton) return;
    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 0) {
          setShowResendButton(true);
          clearInterval(countdown);
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(countdown);
  }, [showResendButton]);

  const checkUtms = async () => {
    try {
      const response = await fetchApiGet(jwtToken, ApiName.utm_list, "");
      if (response.status === 200) {
        adNetwork =
          response.response.find((e: any) => state.utm === e.sf_utm)
            ?.ad_network || "";
      }
    } catch (error) {
      console.log("UTM check error", error);
    }
  };

  const handleSpecialFlow = () => {
    const [one, two, three, four] = state.otp.split("");
    setOtp({ one, two, three, four });
    const apiName = state.isFastFlow ? ApiName.ffSubscribe : ApiName.subscribe;
    setTimeout(
      () => subscribeUser(apiName, one + two + three + four),
      state.specialFlowTime ?? 0
    );
  };

  const handleOtpInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setShowError(false);
    const value = e.target.value;
    if (!isNaN(Number(value)) && value !== " ") {
      setOtp({ ...otp, [e.target.name]: value });
      if (index < inputRefs.length - 1 && value.length === 1) {
        inputRefs[index + 1]?.current?.focus();
      }
    } else {
      displayError("Please enter a valid OTP");
    }
  };

  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && e.currentTarget.value === "" && index > 0) {
      inputRefs[index - 1]?.current?.focus();
    }
  };

  const onOtpSubmit = () => {
    const enteredOtp = Object.values(otp).join("");
    if (enteredOtp.length === 4) {
      state.isExists
        ? loginUser(enteredOtp)
        : subscribeUser(ApiName.subscribe, enteredOtp);
    } else {
      displayError("Enter a valid OTP");
    }
  };

  const displayError = (message: string) => {
    setLoader(false);
    setShowError(true);
    setError(message);
  };

  const subscribeUser = async (apiName: string, enteredOtp: string) => {
    try {
      setLoader(true);
      const data = {
        msisdn: state.msisdn,
        otp: enteredOtp,
        subPackage: state.subPackage,
        utm_source: state.utm,
      };
      const response = await fetchApiPost(jwtToken, apiName, data, "");
      if (response.status === 0) {
        triggerSubscriptionEvent(adNetwork, state.utm);
        navigateToHome(state.msisdn, state.subPackage, response.sessionId);
      } else {
        displayError("Unable to Subscribe");
      }
    } catch (error) {
      displayError("Something went wrong, Please try again.");
    }
  };

  const loginUser = async (enteredOtp: string) => {
    try {
      setLoader(true);
      const data = { msisdn: state.msisdn, otp: enteredOtp };
      const response = await fetchApiPost(jwtToken, ApiName.login, data, "");
      if (response.status === 0) {
        navigateToHome(state.msisdn, state.subPackage, response.sessionId);
      } else {
        displayError("Unable to Subscribe");
      }
    } catch (error) {
      displayError("Something went wrong, Please try again.");
    }
  };

  const onResendOtp = async () => {
    try {
      setTimer(30);
      setShowResendButton(false);
      const data = { msisdn: state.msisdn, os: "web" };
      const response = await fetchApiPost(
        jwtToken,
        ApiName.signupweb,
        data,
        ""
      );
      if (response.status === 0) {
        setOtp({ one: "", two: "", three: "", four: "" });
        inputRefs[0].current?.focus();
        setShowError(false);
      } else {
        displayError("Unable to send OTP, Please try again");
      }
    } catch (error) {
      console.log("signUpWeb" + error);
      displayError("Something went wrong, Please try again.");
    }
  };

  return (
    <OtpPage
      inputRef={inputRefs}
      otp={otp}
      handleOtpInput={handleOtpInput}
      handleBackspace={handleBackspace}
      onOtpSubmit={onOtpSubmit}
      error={error}
      loader={loader}
      showErro={showError}
      onResendOtp={onResendOtp}
      timer={timer}
      showResendButton={showResendButton}
    />
  );
};

export default OtpPageScreen;
