export const ApiName = {
  generateGwtToken: "authenticateWeb",
  checkOperator: "user/checkOperator",
  signupweb: "user/signupWeb",
  verifyOtp: "user/verifyOtpWeb",
  subscribe: "user/subscribeWeb",
  ffSubscribe: "user/ffSubscribe",
  login: "user/loginUser",
  utm_list: "user/utmList",
  unSubscribe: "user/unsubscribeWeb",
  checkUser: "user/CheckUser",
  getFLow: "user/getFlow",
  awarenessFLow: "user/awarenessFlow",
  changePackageWeb: "user/change/package",
  package_list: "user/packages",
  validateHe: "validateHe",
  validateSignature: "validate/signature",
  validateSubscribe: "validate/subscribe",
};
