import { encode as base64_encode } from "base-64";
import tagManagerEvents from "./GoogleTagManager";
export const navigateToHome = (
  msisdn: string,
  subPackage: any,
  sessionId: string
) => {
  const encodedMsisdn = base64_encode(msisdn);
  const packageType =
    subPackage.toLowerCase() === "pre" ? "prepaid" : "postpaid";
  const encodedPackage = base64_encode(packageType);
  const _sessionId = base64_encode(sessionId);
  let url =
    `${window.location.origin}/home?user=${encodedMsisdn}&package=${encodedPackage}&utmInfo=${_sessionId}`.replace(
      "http:",
      "https:"
    );
  window.location.replace(url);
};

export const triggerSubscriptionEvent = (adNetwork: string, utmsource: any) => {
  const tagName =
    adNetwork.toLowerCase() === "tiktok" ||
    adNetwork.toLowerCase() === "tik tok"
      ? "econTikTok"
      : adNetwork.toLowerCase() === "google"
      ? "econceptions"
      : utmsource;
  tagManagerEvents("SUBSCRIBE", tagName);
};

export const redirct = () => {
  let url = window.location.origin;
  url = `${url}/home`;
  if (url.includes("http:")) {
    url = url.replace("http", "https");
  }
  window.location.replace(url);
};
