import React from "react";
import logo from "../Assets/imgs/landing-logo.webp";
import mainimage from "../Assets/imgs/landing-main-img.webp";
import "../styles/landing.css";

type Props = {
  msisdn: string;
  handleUserMsisdnInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPressContinueBtn: () => void;
  error: string;
  loader: boolean;
  showErro: boolean;
};

const LandingPage = (props: Props) => {
  return (
    <div className="landing-bg startalk-landing">
      <div className="subscrption-page">
        <div className="subscrption-logo">
          <img src={logo} width={100} height={100} alt="logo image" />
        </div>
      </div>

      <div className="subscrption-content ">
        <div className="subscrption-inner">
          <div className="image">
            <img src={mainimage} width={355} height={323} alt="image" />
          </div>
          <div className="form">
            <div className="input-content">
              <div className="text">Please enter a 10-digit mobile number.</div>
              <div className="code-number">
                <div className="code">
                  <div className="fixed-cnt">+92</div>
                </div>
                <div className="input">
                  <input
                    type="tel"
                    value={props.msisdn}
                    placeholder="3xx-xxxx-xxx"
                    onChange={(e) => {
                      props.handleUserMsisdnInput(e);
                    }}
                    max={10}
                  />
                </div>
              </div>
            </div>

            {props.showErro ? (
              <>
                <p className="error">{props.error}</p>
              </>
            ) : (
              <></>
            )}

            {props.loader ? (
              <>
                <button className="disable-btn">
                  <span>Loading...</span>
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    props.onPressContinueBtn();
                  }}
                >
                  Get OTP
                </button>
              </>
            )}

            {/* <button>Send OTP</button> */}

            <div className="prepaid-text">
              * Note: Only for Rs. 5 incl of Tax/Day (Prepaid) *
            </div>

            <div className="condition">
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
              <span></span>
              <a href="/terms" target="_blank">
                Terms & Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
