import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ApiName } from "../Constant/ApiName";
import tagManagerEvents from "../utils/GoogleTagManager";
import LandingPage from "../pages/LandingPage";
import { fetchApiGet, fetchApiPost } from "../services/NetWorkServices";
import { getFlowServices } from "../services/SupscriptionServices";
import { navigateToHome } from "../utils/CommonFunction";

type Props = {
  jwtToken: string | null;
};

const LandingPageScreen = ({ jwtToken }: Props) => {
  const navigate = useNavigate();
  const [msisdn, setMsisdn] = useState<string>("");
  const [utm, setUtm] = useState<string | null>(null);
  const [subPackage, setSubPackage] = useState<string>("");
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(false);
  let heMsisdn: string = "";

  useEffect(() => {
    if (jwtToken) fetchUtmAndFlow();
  }, [jwtToken]);

  const fetchUtmAndFlow = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get("utm_source");
    setUtm(utmSource);
    fetchFlowData(utmSource);
  };

  const fetchFlowData = useCallback(
    async (utmSource: string | null) => {
      try {
        const response = await getFlowServices(
          jwtToken,
          `${ApiName.getFLow}?utm_source=${utmSource}`
        );
        if (response.status !== 0) return;

        const {
          isSpecialFlow,
          msisdn: responseMsisdn,
          otp,
          subPackage: responseSubPackage,
          signIn,
          fastFlow,
          milliseconds,
          sessionId,
        } = response.result;

        if (
          isSpecialFlow &&
          responseMsisdn?.length === 10 &&
          otp?.length === 4
        ) {
          const specialFlowData = {
            msisdn: responseMsisdn,
            otp,
            isSpecialFlow,
            isFastFlow: fastFlow,
            specialFlowTime: milliseconds,
            subPackage: responseSubPackage,
            utm: utmSource,
          };
          setMsisdn(responseMsisdn);
          navigate("/otp", { state: specialFlowData });
        } else if (responseMsisdn?.length === 10) {
          handleNormalFlow(
            responseMsisdn,
            responseSubPackage,
            signIn,
            utmSource,
            sessionId
          );
        } else {
          tagManagerEvents("WIFI_USER", utmSource);
        }
      } catch (error) {
        console.error("Error fetching flow data:", error);
      }
    },
    [jwtToken, navigate]
  );

  const handleNormalFlow = (
    msisdn: string,
    subPackage: any,
    signIn: boolean,
    utm: string | null,
    sessionId: any
  ) => {
    tagManagerEvents("DATA_USER", utm);
    setMsisdn(msisdn);
    heMsisdn = msisdn;
    if (subPackage) setSubPackage(subPackage);
    if (signIn) navigateToHome(msisdn, subPackage, sessionId);
  };

  const handleUserMsisdnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value;

    if (
      !userInput ||
      (userInput.length <= 10 && /^3\d{0,9}$/.test(userInput))
    ) {
      setShowError(false);
      setMsisdn(userInput);
    }
  };

  const handleContinuePress = () => {
    if (msisdn.length === 10) {
      console.log("::::::::", msisdn);
      setShowError(false);
      msisdn === heMsisdn && subPackage
        ? sendOtp(subPackage)
        : validateOperator();
    } else {
      setLoader(false);
      setShowError(true);
      setError("Please enter a 10-digit mobile number");
    }
  };

  console.log(msisdn);

  const validateOperator = async () => {
    try {
      setLoader(true);
      const response = await fetchApiGet(
        jwtToken,
        `${ApiName.checkOperator}/${msisdn}`,
        ""
      );
      if (response.status === 0) {
        response.network.toLowerCase() !== "others"
          ? sendOtp(response.package)
          : handleInvalidOperator();
      } else {
        handleApiError("Something went wrong");
      }
    } catch (error) {
      handleApiError("Something went wrong", error);
    }
  };

  const sendOtp = async (subPackage: string) => {
    try {
      setLoader(true);
      const response = await fetchApiPost(
        jwtToken,
        ApiName.signupweb,
        {
          msisdn,
          os: "web",
        },
        ""
      );
      if (response.status === 0) {
        tagManagerEvents("REQUEST_OTP", utm);
        const otpData = {
          msisdn,
          otp: msisdn === heMsisdn ? response.otp : "",
          utm,
          isExists:
            response.exists && response.package?.toLowerCase() !== "subt",
          subPackage,
        };
        setLoader(false);
        navigate("/otp", { state: otpData });
      } else {
        handleApiError("Something went wrong");
      }
    } catch (error) {
      handleApiError("Something went wrong", error);
    }
  };

  const handleApiError = (message: string, error?: any) => {
    setLoader(false);
    setShowError(true);
    setError(message);
    error && console.error(message, error);
  };

  const handleInvalidOperator = () => {
    setLoader(false);
    setShowError(true);
    setError(
      "Service is only available for Zong customers; please enter a working Zong number"
    );
  };

  return (
    <LandingPage
      msisdn={msisdn}
      handleUserMsisdnInput={handleUserMsisdnInput}
      onPressContinueBtn={handleContinuePress}
      error={error}
      loader={loader}
      showErro={showError}
    />
  );
};

export default LandingPageScreen;
