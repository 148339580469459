import { baseUrl } from "../utils/config";

const newBaseUrl = () => {
  if (window.location.protocol === "http:") {
    let newBaseUrl: string | undefined = baseUrl;
    newBaseUrl = newBaseUrl?.replace("https", "http");
    return newBaseUrl;
  } else {
    return baseUrl;
  }
};

export const fetchApiGet = async (
  token: string | null,
  endPoint: string,
  sessionID: string | null
) => {
  let response = await fetch(`${newBaseUrl()}${endPoint}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? "Bearer " + token : "",
      "Auth-Session-Id": sessionID ? sessionID : "",
    },
  });
  let jsonResponse = await response.json();
  return jsonResponse;
};

export const fetchApiPost = async (
  token: string | null,
  endPoint: string,
  data: any,
  sessionID: string | null
) => {
  let response = await fetch(`${newBaseUrl()}${endPoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? "Bearer " + token : "",
      "Auth-Session-Id": sessionID ? sessionID : "",
    },
    body: JSON.stringify(data),
  });
  let jsonResponse = await response.json();
  return jsonResponse;
};
